import { React, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import { getAllOrdersList } from '../actions/orderActions'

const OrderListScreen = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const orderList = useSelector(state => state.orderList)
	const { loading, error, orders } = orderList

	const userLogin = useSelector(state => state.userLogin)
	const { userInfo } = userLogin

	useEffect(() => {
		if (userInfo && userInfo.isAdmin) {
			dispatch(getAllOrdersList())
		} else {
			navigate('/login')
		}
	}, [dispatch, navigate, userInfo])

	return (
		<>
			<h1>Orders</h1>
			{loading ? (
				<Loader />
			) : error ? (
				<Message variant='danger'>{error}</Message>
			) : (
				<Table
					striped
					bordered
					hover
					responsive
					className='table-sm'>
					<thead>
						<tr>
							<th>ID</th>
							<th>USER</th>
							<th>DATE</th>
							<th>TOTAL</th>
							<th>PAID</th>
							<th>DELIVERED</th>
						</tr>
					</thead>
					<tbody>
						{orders.map(order => (
							<tr key={order._id}>
								<td>{order._id}</td>
								<td>{order.user && order.user.name}</td>
								<td>
									{order.createdAt.substring(1, 10)}
								</td>
								<td>{order.totalPrice}</td>
								<td>
									{order.isPaid ? (
										<i
											className='fas fa-check'
											style={{
												color: 'green',
											}}></i>
									) : (
										<i
											className='fas fa-times'
											style={{
												color: 'red',
											}}></i>
									)}
								</td>
								<td>
									{order.isDelivered ? (
										<i
											className='fas fa-check'
											style={{
												color: 'green',
											}}></i>
									) : (
										<i
											className='fas fa-times'
											style={{
												color: 'red',
											}}></i>
									)}
								</td>
								<td>
									<LinkContainer
										to={`/orders/${order._id}`}>
										<Button
											variant='light'
											className='btsm'>
											DETAILS
										</Button>
									</LinkContainer>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
			)}
		</>
	)
}

export default OrderListScreen
